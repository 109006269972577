"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Auth_1 = require("../../context/Auth");
var MenuAuthContainer_styled_1 = require("./MenuAuthContainer.styled");
var icons_material_1 = require("@mui/icons-material");
var components_1 = require("../../components");
var MenuAuthContainer = function () {
    var _a = (0, Auth_1.useAuth)(), logout = _a.logout, user = _a.user;
    return ((0, jsx_runtime_1.jsx)(components_1.MenuComponent, { target: (0, jsx_runtime_1.jsxs)(MenuAuthContainer_styled_1.AuthContent, { children: [(0, jsx_runtime_1.jsx)(MenuAuthContainer_styled_1.IconWrapper, { children: (0, jsx_runtime_1.jsx)(icons_material_1.AccountCircle, {}) }), user ? ((0, jsx_runtime_1.jsxs)(MenuAuthContainer_styled_1.AuthContentText, { children: [(0, jsx_runtime_1.jsx)("h6", { children: user.first_name + ' ' + user.first_last_name }), (0, jsx_runtime_1.jsx)("p", { children: user.email })] })) : ((0, jsx_runtime_1.jsx)("p", { children: "Cargando..." }))] }), renderItems: function () { return [
            (0, jsx_runtime_1.jsxs)(material_1.MenuItem, __assign({ onClick: logout, disableRipple: true }, { children: [(0, jsx_runtime_1.jsx)(material_1.ListItemIcon, { children: (0, jsx_runtime_1.jsx)(icons_material_1.Logout, { fontSize: "small" }) }), (0, jsx_runtime_1.jsx)(material_1.ListItemText, { primary: "Cerrar sesi\u00F3n" })] }), "menu-logout"),
        ]; } }));
};
exports.default = MenuAuthContainer;
