"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCustom = exports.TableContainerCustom = exports.WrapperTable = exports.ActionsTableWrapper = void 0;
var system_1 = require("@mui/system");
var material_1 = require("@mui/material");
exports.ActionsTableWrapper = (0, system_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
}); });
exports.WrapperTable = (0, system_1.styled)(material_1.Paper)(function () { return ({
    width: '100%',
}); });
exports.TableContainerCustom = (0, system_1.styled)(material_1.TableContainer)(function () { return ({
    overflowY: 'initial',
    maxHeight: 'calc(100vh - 400px)',
}); });
exports.TableCustom = (0, system_1.styled)(material_1.Table)(function () { return ({
    minWidth: 750,
    borderTop: '1px solid #e1e1e1',
}); });
