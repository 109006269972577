"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchLogin = void 0;
var react_query_1 = require("react-query");
var services_1 = require("../../../services");
var utilities_1 = require("../../../utilities");
var useFetchLogin = function () {
    return (0, react_query_1.useMutation)(function (data) { return services_1.UserService.login(data); }, {
        onError: function (error) {
            (0, utilities_1.handleError)(error);
        },
    });
};
exports.useFetchLogin = useFetchLogin;
