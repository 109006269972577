"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
function SectionTitle(_a) {
    var title = _a.title, renderRight = _a.renderRight;
    return ((0, jsx_runtime_1.jsxs)(system_1.Box, __assign({ sx: {
            marginTop: '20px',
            marginBottom: '30px',
            display: 'flex',
            gap: '20px',
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'wrap' },
        } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, __assign({ variant: "h5", fontWeight: 600 }, { children: title })), renderRight && ((0, jsx_runtime_1.jsx)(system_1.Box, __assign({ sx: { display: 'flex', gap: '10px' } }, { children: renderRight })))] })));
}
exports.default = SectionTitle;
