"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentLeft = exports.EventCard = void 0;
var material_1 = require("@mui/material");
var colors_1 = require("@mui/material/colors");
var system_1 = require("@mui/system");
exports.EventCard = (0, system_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: 15,
            borderRadius: 16,
            border: "1px solid ".concat(colors_1.grey[300]),
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '10px',
            alignItems: 'flex-start'
        },
        _b[theme.breakpoints.up('sm')] = {
            gap: '20px',
            alignItems: 'center',
            gridTemplateColumns: '6rem 12rem 1fr 8rem 8rem 9rem',
        },
        _b);
});
exports.ContentLeft = (0, system_1.styled)(material_1.Box)(function () { return ({
    '& h5,p': {
        margin: 0,
    },
    '& p': {
        color: "".concat(colors_1.grey[600]),
        fontSize: 14,
    },
}); });
