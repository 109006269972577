"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayHelper = void 0;
var ArrayHelper = /** @class */ (function () {
    function ArrayHelper() {
    }
    ArrayHelper.generateArray = function (to) {
        if (to === void 0) { to = 5; }
        return Array.from(Array(to).keys());
    };
    return ArrayHelper;
}());
exports.ArrayHelper = ArrayHelper;
