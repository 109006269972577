"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadBold = void 0;
function downloadBold(_a) {
    var data = _a.data, fileName = _a.fileName;
    var url = window.URL.createObjectURL(new Blob([data]));
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}
exports.downloadBold = downloadBold;
