"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var translations_1 = require("../../../translations");
var utilities_1 = require("../../../utilities");
var TransactionItem_styled_1 = require("./TransactionItem.styled");
var TransactionItem = function (_a) {
    var transaction = _a.transaction;
    var color = transaction.transaction_type
        .trim()
        .toLowerCase()
        .includes('credit')
        ? '#61cf59'
        : '#c2544a';
    var signAmount = transaction.transaction_type
        .trim()
        .toLowerCase()
        .includes('credit')
        ? '+'
        : '-';
    return ((0, jsx_runtime_1.jsxs)(TransactionItem_styled_1.TransactionCard, { children: [(0, jsx_runtime_1.jsx)(TransactionItem_styled_1.ContentLeft, __assign({ sx: { display: { xs: 'none', sm: 'block' } } }, { children: (0, jsx_runtime_1.jsxs)("p", { children: ["#", transaction.transaction_confirm] }) })), (0, jsx_runtime_1.jsxs)(TransactionItem_styled_1.ContentLeft, { children: [(0, jsx_runtime_1.jsx)("h5", { children: transaction.transaction_description }), (0, jsx_runtime_1.jsx)("p", { children: transaction.created_at
                            ? (0, utilities_1.formatDate)({ date: transaction.created_at })
                            : null })] }), (0, jsx_runtime_1.jsx)(TransactionItem_styled_1.ContentLeft, __assign({ sx: { display: { xs: 'none', sm: 'block' }, justifySelf: 'end' } }, { children: (0, jsx_runtime_1.jsx)("p", { children: (0, utilities_1.amountFormatByCurrencyCode)({
                        amount: Number(transaction.previous_balance),
                        currency_code: transaction.currency_code,
                    }) }) })), (0, jsx_runtime_1.jsx)(TransactionItem_styled_1.ContentLeft, __assign({ sx: { display: { xs: 'none', sm: 'block' }, justifySelf: 'end' } }, { children: (0, jsx_runtime_1.jsx)("p", { children: (0, utilities_1.amountFormatByCurrencyCode)({
                        amount: Number(transaction.current_balance),
                        currency_code: transaction.currency_code,
                    }) }) })), (0, jsx_runtime_1.jsx)(TransactionItem_styled_1.ContentLeft, __assign({ sx: { display: { xs: 'none', sm: 'block' } } }, { children: (0, jsx_runtime_1.jsx)("p", { children: translations_1.localeEs['merchant_transaction_type'][transaction.transaction_type] }) })), (0, jsx_runtime_1.jsx)(TransactionItem_styled_1.ContentRight, { children: (0, jsx_runtime_1.jsxs)("p", __assign({ style: { color: color } }, { children: [signAmount, (0, utilities_1.amountFormatByCurrencyCode)({
                            amount: Number(transaction.transaction_amount),
                            currency_code: transaction.currency_code,
                        })] })) })] }));
};
exports.default = TransactionItem;
