"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardLoading = exports.TransactionListDetailWrapper = void 0;
var material_1 = require("@mui/material");
var colors_1 = require("@mui/material/colors");
var system_1 = require("@mui/system");
exports.TransactionListDetailWrapper = (0, system_1.styled)(material_1.Box)(function () { return ({
    display: 'grid',
    gap: 10,
    paddingBottom: '20px',
    overflowX: 'auto',
}); });
exports.CardLoading = (0, system_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: 15,
            borderRadius: 16,
            border: "1px solid ".concat(colors_1.grey[300]),
            display: 'grid',
            gridTemplateColumns: '1fr 6rem',
            gap: 20,
            alignItems: 'center'
        },
        _b[theme.breakpoints.up('sm')] = {
            gridTemplateColumns: '4rem 1fr 1fr 6rem',
        },
        _b);
});
