"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconWrapper = exports.AuthContentText = exports.AuthContent = void 0;
var material_1 = require("@mui/material");
var colors_1 = require("@mui/material/colors");
var system_1 = require("@mui/system");
exports.AuthContent = (0, system_1.styled)('div')(function () { return ({
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '6px',
    overflow: 'hidden',
    display: 'flex',
    gap: '10px',
    '&:hover': { backgroundColor: "".concat(colors_1.grey[100]) },
}); });
exports.AuthContentText = (0, system_1.styled)(material_1.Box)(function () { return ({
    '& h6, p': { margin: 0, fontSize: '13px' },
}); });
exports.IconWrapper = (0, system_1.styled)('div')(function () { return ({
    display: 'grid',
    placeItems: 'center',
}); });
