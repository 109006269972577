"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterHelper = void 0;
var FilterHelper = /** @class */ (function () {
    function FilterHelper() {
    }
    FilterHelper.getSearchOptional = function (search) {
        var searchOptional = {};
        if (search && search !== '')
            searchOptional = __assign(__assign({}, searchOptional), { term: search });
        return searchOptional;
    };
    FilterHelper.getFilterOptional = function (filterList) {
        var filterOptional = {};
        Object.keys(filterList).forEach(function (key) {
            var _a;
            var keyObject = key;
            if (filterList[keyObject] !== '') {
                filterOptional = __assign(__assign({}, filterOptional), (_a = {}, _a[keyObject] = filterList[keyObject], _a));
            }
        });
        return filterOptional;
    };
    FilterHelper.formatFilterMultipleOptional = function (filterList) {
        var filterOptional = {};
        Object.keys(filterList).forEach(function (key) {
            var _a, _b;
            var keyObject = key;
            var keyNew = keyObject.toString().replace(/[[]]/g, '');
            if (Array(filterList[keyObject]).length > 0) {
                var newFilter = String(keyObject).endsWith(']')
                    ? (_a = {},
                        _a[keyNew] = Array(filterList[keyObject])
                            .join(',')
                            .replace(/^/, '{')
                            .replace(/$/, '}'),
                        _a) : !!filterList[keyObject][0]
                    ? (_b = {},
                        _b[keyNew] = filterList[keyObject][0],
                        _b) : {};
                filterOptional = __assign(__assign({}, filterOptional), newFilter);
            }
        });
        return filterOptional;
    };
    FilterHelper.convertObjectArrayToObject = function (array) {
        return array.reduce(function (result, item) {
            var key = Object.keys(item)[0]; //first property: a, b, c
            result[key] = item[key];
            return result;
        }, {});
    };
    FilterHelper.getFilterSelectedQueryFormat = function (applyFilters, filterList) {
        return Object.keys(filterList)
            .map(function (key) {
            var _a;
            var simpleKey = key.toString().replace(/[[]]/g, '');
            var newValue = applyFilters
                .filter(function (item) { return item.key === simpleKey; })
                .map(function (item) {
                var _a;
                return (_a = {}, _a[item.key] = item.dbKey, _a);
            })
                .map(function (item) { return item[simpleKey]; });
            return _a = {}, _a[key] = newValue, _a;
        })
            .reduce(function (acc, item) {
            var key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
        });
    };
    FilterHelper.getSimpleFilterValues = function (filterList) {
        var arrayFilter = [];
        Object.keys(filterList).forEach(function (key) {
            var filterArrayValue = filterList[key];
            if (filterArrayValue.length > 0) {
                (filterArrayValue !== null && filterArrayValue !== void 0 ? filterArrayValue : []).forEach(function (value) {
                    var _a;
                    arrayFilter.push((_a = {}, _a['queryValue'] = value, _a));
                });
            }
        });
        return arrayFilter;
    };
    FilterHelper.getFilterListToObjectQueryParams = function (_a) {
        var objectListFilter = _a.objectListFilter;
        return objectListFilter
            .map(function (filter) {
            var _a;
            return (_a = {}, _a[filter.key] = filter.dbKey, _a);
        })
            .reduce(function (acc, item) {
            var key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
        }, {});
    };
    return FilterHelper;
}());
exports.FilterHelper = FilterHelper;
