"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var optionFilterList = [
    { filter: 'Estado', key: 'status', value: 'Pendiente', dbKey: 'PENDING' },
    { filter: 'Estado', key: 'status', value: 'Activo', dbKey: 'ACTIVE' },
    { filter: 'Estado', key: 'status', value: 'Inactivo', dbKey: 'INACTIVE' },
    { filter: 'Estado', key: 'status', value: 'Cancelado', dbKey: 'CANCELED' },
    { filter: 'Estado', key: 'status', value: 'Suspendido', dbKey: 'SUSPENDED' },
    { filter: 'Estado', key: 'status', value: 'Bloqueado', dbKey: 'BLOCKED' },
];
exports.default = optionFilterList;
