"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlobalState = void 0;
var react_query_1 = require("react-query");
var GLOBAL_STATE_KEY_PREFIX = 'globalState';
function useGlobalState(key, initialState) {
    var queryClient = (0, react_query_1.useQueryClient)();
    var stateKey = [GLOBAL_STATE_KEY_PREFIX, key];
    var data = (0, react_query_1.useQuery)(stateKey, function () { return initialState; }, {
        initialData: initialState,
        staleTime: Infinity,
    }).data;
    var setData = function (newState) {
        queryClient.setQueryData(stateKey, newState);
    };
    return [data, setData];
}
exports.useGlobalState = useGlobalState;
