"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_query_1 = require("react-query");
var constants_1 = require("../../../constants");
var utilities_1 = require("../../../utilities");
var react_router_dom_1 = require("react-router-dom");
var services_1 = require("../../../services");
var react_error_boundary_1 = require("react-error-boundary");
var useFetchCampaignsAdmin = function () {
    var _a, _b, _c;
    var _d = (0, react_router_dom_1.useSearchParams)(), searchParams = _d[0], setSearchParams = _d[1];
    var showBoundary = (0, react_error_boundary_1.useErrorBoundary)().showBoundary;
    var queryParamsData = __assign(__assign(__assign({}, utilities_1.FilterHelper.getSearchOptional((_a = searchParams.get('term')) !== null && _a !== void 0 ? _a : '')), utilities_1.FilterHelper.formatFilterMultipleOptional(utilities_1.QueryParamsUtils.getQueryParamsMultipleInitialValues({
        initialParamValues: { 'status[]': [], merchant_id: [] },
        queryParams: searchParams,
    }))), { page: Number((_b = searchParams.get('page')) !== null && _b !== void 0 ? _b : 1), per_page: Number((_c = searchParams.get('rowsPerPage')) !== null && _c !== void 0 ? _c : 15) });
    return (0, react_query_1.useQuery)([constants_1.GET_ALL_CAMPAIGNS_FOR_ADMIN, queryParamsData], function () { return services_1.CampaignService.getAllCampaignsForAdmin(queryParamsData); }, {
        staleTime: 1000 * 60 * 30,
        onSuccess: function (data) {
            if (data.data.length === 0) {
                (0, utilities_1.resetPaginationQuery)(searchParams, setSearchParams);
            }
        },
        onError: function (error) {
            (0, utilities_1.handleError)(error);
            showBoundary(error);
        },
    });
};
exports.default = useFetchCampaignsAdmin;
