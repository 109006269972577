"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_error_boundary_1 = require("react-error-boundary");
var react_query_1 = require("react-query");
var constants_1 = require("../../../constants");
var services_1 = require("../../../services");
var utilities_1 = require("../../../utilities");
var useFetchCardEventLogsByClientCardId = function (id) {
    var showBoundary = (0, react_error_boundary_1.useErrorBoundary)().showBoundary;
    return (0, react_query_1.useQuery)([constants_1.GET_CARD_EVENT_LOGS, id], function () { return services_1.ClientCardService.getEventLogs(id); }, {
        staleTime: 1000 * 60 * 30,
        onError: function (error) {
            (0, utilities_1.handleError)(error);
            showBoundary(error);
        }
    });
};
exports.default = useFetchCardEventLogsByClientCardId;
