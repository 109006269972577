"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../../../services");
var react_query_1 = require("react-query");
var constants_1 = require("../../../constants");
var utilities_1 = require("../../../utilities");
var react_error_boundary_1 = require("react-error-boundary");
var useFetchAllCampaignsByMerchantId = function (id, options) {
    var showBoundary = (0, react_error_boundary_1.useErrorBoundary)().showBoundary;
    return (0, react_query_1.useQuery)([constants_1.GET_ALL_CAMPAIGNS_BY_MERCHANT_ID, id], function () { return services_1.MerchantService.getAllCampaignsByMerchantId(id !== null && id !== void 0 ? id : ''); }, {
        enabled: !!id && (options === null || options === void 0 ? void 0 : options.enabled),
        onSuccess: options === null || options === void 0 ? void 0 : options.onSuccess,
        onError: function (error) {
            (0, utilities_1.handleError)(error);
            showBoundary(error);
        },
    });
};
exports.default = useFetchAllCampaignsByMerchantId;
