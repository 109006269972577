"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogTitleCustom = exports.DialogHeader = void 0;
var system_1 = require("@mui/system");
exports.DialogHeader = (0, system_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}); });
exports.DialogTitleCustom = (0, system_1.styled)('div')(function () { return ({
    fontSize: '18px',
    fontWeight: 700,
}); });
