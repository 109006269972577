"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThemeProvider = exports.ThemeMdProvider = exports.theme = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var locale_1 = require("@mui/material/locale");
var styles_1 = require("../styles");
exports.theme = (0, material_1.createTheme)({
    palette: {
        mode: 'light',
        primary: {
            light: styles_1.colors.gbc.skyBlue,
            main: styles_1.colors.gbc.blue,
            dark: styles_1.colors.gbc.darkBlue,
        },
        secondary: {
            light: '#E5E5FF',
            main: styles_1.colors.gbc.violet,
        },
    },
}, locale_1.esES);
var values = {
    theme: 'light',
    toggleTheme: function () { },
};
var themeContext = (0, react_1.createContext)(values);
var ENUM_THEME;
(function (ENUM_THEME) {
    ENUM_THEME["LIGHT"] = "light";
    ENUM_THEME["DARK"] = "dark";
})(ENUM_THEME || (ENUM_THEME = {}));
var ThemeMdProvider = function (props) {
    var _a = (0, react_1.useState)(ENUM_THEME.LIGHT), themeMd = _a[0], setThemeMd = _a[1];
    var toggleTheme = function () {
        setThemeMd(themeMd === ENUM_THEME.LIGHT ? ENUM_THEME.LIGHT : ENUM_THEME.DARK);
    };
    return ((0, jsx_runtime_1.jsx)(themeContext.Provider, __assign({ value: { theme: themeMd, toggleTheme: toggleTheme } }, { children: (0, jsx_runtime_1.jsx)(material_1.ThemeProvider, __assign({ theme: exports.theme }, { children: props.children })) })));
};
exports.ThemeMdProvider = ThemeMdProvider;
var useThemeProvider = function () { return (0, react_1.useContext)(themeContext); };
exports.useThemeProvider = useThemeProvider;
