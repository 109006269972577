"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidationError = void 0;
var getValidationError = function (errorCode) {
    var codeMatcher = {
        ERR_NETWORK: 'Error desconocido contactarse con el soporte técnico',
    };
    return codeMatcher[errorCode];
};
exports.getValidationError = getValidationError;
