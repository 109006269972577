"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var usePageVisibility = function () {
    var _a = (0, react_1.useState)(true), isPageVisible = _a[0], setIsPageVisible = _a[1];
    var handleVisibilityChange = (0, react_1.useCallback)(function () {
        setIsPageVisible(!document.hidden);
    }, [document.hidden]);
    (0, react_1.useEffect)(function () {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return function () {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return isPageVisible;
};
exports.default = usePageVisibility;
