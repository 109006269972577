"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientCardUtils = void 0;
var ClientCardUtils = /** @class */ (function () {
    function ClientCardUtils() {
    }
    ClientCardUtils.getCardNumberFormat = function (cardNumber) {
        var _a, _b;
        return (_b = (_a = cardNumber.toString().match(/.{4}/g)) === null || _a === void 0 ? void 0 : _a.join(' ')) !== null && _b !== void 0 ? _b : '';
    };
    return ClientCardUtils;
}());
exports.ClientCardUtils = ClientCardUtils;
