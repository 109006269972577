"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function usePersistedState(key, initialState) {
    var _a = (0, react_1.useState)(function () {
        var storageValue = localStorage.getItem(key);
        if (storageValue && storageValue !== undefined) {
            return JSON.parse(storageValue);
        }
        return initialState;
    }), state = _a[0], setState = _a[1];
    (0, react_1.useEffect)(function () {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
exports.default = usePersistedState;
