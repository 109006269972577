"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpanText = exports.FilterHelperContainer = void 0;
var system_1 = require("@mui/system");
exports.FilterHelperContainer = (0, system_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            fontSize: 14
        },
        _b[theme.breakpoints.up('sm')] = {
            fontSize: '1rem',
        },
        _b);
});
exports.SpanText = (0, system_1.styled)('p')(function () { return ({
    fontWeight: 700,
    margin: 0,
}); });
