"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionColumn = exports.TransactionHead = exports.CardLoading = exports.TransactionListDetailWrapper = exports.BalanceSubTitle = exports.BalanceCard = exports.ContentText = exports.SubTitle = exports.ContentCard = exports.DetailContent = exports.Description = exports.IconWrapper = exports.HeadContent = exports.ContentWrapper = exports.HeaderContent = void 0;
var colors_1 = require("@mui/material/colors");
var styles_1 = require("@mui/material/styles");
var system_1 = require("@mui/system");
exports.HeaderContent = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '0px',
            width: '100%'
        },
        _b[theme.breakpoints.up('sm')] = {
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: '10px',
        },
        _b);
});
exports.ContentWrapper = (0, styles_1.styled)('div')(function () { return ({
    marginTop: '20px',
}); });
exports.HeadContent = (0, styles_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '10px',
}); });
exports.IconWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '46px',
        height: '46px',
        borderRadius: '5px',
        display: 'flex',
        placeItems: 'center',
        padding: '10px',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
    });
});
exports.Description = (0, styles_1.styled)('h3')(function () { return ({
    fontSize: '18px',
    fontWeight: '500',
}); });
exports.DetailContent = (0, styles_1.styled)('div')(function () { return ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '10px',
}); });
exports.ContentCard = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
    });
});
exports.SubTitle = (0, styles_1.styled)('h6')(function () { return ({
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
}); });
exports.ContentText = (0, styles_1.styled)('p')(function () { return ({
    fontSize: 16,
    fontWeight: 400,
    color: "".concat(colors_1.grey[600]),
    margin: 0,
}); });
exports.BalanceCard = (0, styles_1.styled)(system_1.Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: 'relative',
            width: '100%',
            overflow: 'hidden'
        },
        _b[theme.breakpoints.up('sm')] = {
            width: '346px',
        },
        _b.borderRadius = '16px',
        _b.padding = '25px',
        _b.background = theme.palette.primary.main,
        _b.display = 'grid',
        _b.gridTemplateColumns = '1fr 5rem',
        _b.gap = '20px',
        _b);
});
exports.BalanceSubTitle = (0, styles_1.styled)('h6')(function () { return ({
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    color: 'rgba(255 255 255 / 0.7)',
}); });
exports.TransactionListDetailWrapper = (0, styles_1.styled)(system_1.Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'grid',
            gap: 10,
            paddingBottom: '10px',
            overflowX: 'auto'
        },
        _b[theme.breakpoints.up('sm')] = {
            paddingBottom: '5px',
        },
        _b);
});
exports.CardLoading = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: 15,
            borderRadius: 16,
            border: "1px solid ".concat(colors_1.grey[300]),
            display: 'grid',
            gridTemplateColumns: '1fr 6rem',
            gap: 20,
            alignItems: 'center'
        },
        _b[theme.breakpoints.up('sm')] = {
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 6rem',
            gap: 40,
        },
        _b);
});
exports.TransactionHead = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: '10px 15px',
            borderRadius: 16,
            border: "1px solid ".concat(colors_1.grey[300]),
            display: 'grid',
            gridTemplateColumns: '1fr 6rem',
            gap: 20,
            alignItems: 'center',
            marginBottom: '5px'
        },
        _b[theme.breakpoints.up('sm')] = {
            gridTemplateColumns: '5rem 1fr 0.5fr 0.5fr 4rem 6rem',
            gap: 40,
        },
        _b);
});
exports.TransactionColumn = (0, styles_1.styled)(system_1.Box)(function () { return ({
    '& h5': {
        margin: 0,
    },
}); });
