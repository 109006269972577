"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_router_dom_1 = require("react-router-dom");
function TablePaginationComponent(_a) {
    var _b;
    var total = _a.total;
    var _c = (0, react_router_dom_1.useSearchParams)(), searchParams = _c[0], setSearchParams = _c[1];
    var page = Number((_b = searchParams.get('page')) !== null && _b !== void 0 ? _b : 1) - 1;
    var rowsPerPage = Number(searchParams.get('rowsPerPage') || 15);
    var handleChangePage = function (event, newPage) {
        searchParams.set('page', String(newPage + 1));
        setSearchParams(searchParams);
    };
    var handleChangeRowsPerPage = function (event) {
        searchParams.set('rowsPerPage', String(parseInt(event.target.value, 10)));
        setSearchParams(searchParams);
    };
    return ((0, jsx_runtime_1.jsx)(material_1.TablePagination, { rowsPerPageOptions: [10, 15, 25], component: "div", count: total, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }));
}
exports.default = TablePaginationComponent;
