"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentWrapper = exports.HeaderContent = void 0;
var styles_1 = require("@mui/material/styles");
exports.HeaderContent = (0, styles_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '10px',
}); });
exports.ContentWrapper = (0, styles_1.styled)('div')(function () { return ({
    marginTop: '20px',
}); });
