"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumCampaignStatus = void 0;
var EnumCampaignStatus;
(function (EnumCampaignStatus) {
    // when upload clients to campaign
    EnumCampaignStatus["PENDING_UPLOAD_FILE_OF_CLIENTS"] = "PENDING_UPLOAD_FILE_OF_CLIENTS";
    EnumCampaignStatus["IN_PROGRESS_UPLOAD_FILE_OF_CLIENTS"] = "IN_PROGRESS_UPLOAD_FILE_OF_CLIENTS";
    EnumCampaignStatus["FAIL_PROCESSING_CLIENTS"] = "FAIL_PROCESSING_CLIENTS";
    EnumCampaignStatus["PENDING_ASSIGN_CARD"] = "PENDING_ASSIGN_CARD";
    // when association card to client
    EnumCampaignStatus["IN_PROGRESS_ASSIGN_CARD"] = "IN_PROGRESS_ASSIGN_CARD";
    EnumCampaignStatus["FAIL_ASSIGNED_CARDS"] = "FAIL_ASSIGNED_CARDS";
    EnumCampaignStatus["SUCCESS_ASSIGNED_CARDS"] = "SUCCESS_ASSIGNED_CARDS";
    // when recharge balance
    EnumCampaignStatus["PENDING_RECHARGE_BALANCES"] = "PENDING_RECHARGE_BALANCES";
    EnumCampaignStatus["FAIL_RECHARGE_BALANCES"] = "FAIL_RECHARGE_BALANCES";
    EnumCampaignStatus["PENDING_SEND_EMAIL"] = "PENDING_SEND_EMAIL";
    EnumCampaignStatus["PROCESS_SEND_EMAIL"] = "PROCESS_SEND_EMAIL";
    EnumCampaignStatus["FAIL_PROCESS_SEND_EMAIL"] = "FAIL_PROCESS_SEND_EMAIL";
    EnumCampaignStatus["SENT_EMAIL_ACTIVATION"] = "SENT_EMAIL_ACTIVATION";
})(EnumCampaignStatus = exports.EnumCampaignStatus || (exports.EnumCampaignStatus = {}));
