"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryIconWrapper = exports.CountCardRight = exports.CountCardLeft = exports.CountCard = exports.SummaryContent = exports.SubTitle = exports.SummaryWrapper = void 0;
var system_1 = require("@mui/system");
var colors_1 = require("@mui/material/colors");
exports.SummaryWrapper = (0, system_1.styled)('div')(function () { return ({
    marginTop: '30px',
}); });
exports.SubTitle = (0, system_1.styled)('h4')(function () { return ({
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '15px',
    color: "".concat(colors_1.grey[600]),
    margin: 0,
}); });
exports.SummaryContent = (0, system_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            marginTop: '20px',
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
        },
        _b[theme.breakpoints.up('sm')] = {
            gap: '40px',
        },
        _b);
});
exports.CountCard = (0, system_1.styled)('div')(function () { return ({
    padding: '20px',
    border: "1px solid ".concat(colors_1.grey.A200),
    borderRadius: '16px',
    flex: 1,
    display: 'flex',
    gap: '10px',
    ':hover': {
        backgroundColor: "".concat(colors_1.grey[100]),
    },
}); });
exports.CountCardLeft = (0, system_1.styled)('div')(function () { return ({}); });
exports.CountCardRight = (0, system_1.styled)('div')(function () { return ({
    '& h5': {
        fontSize: '16px',
        fontWeight: 400,
        margin: 0,
        color: "".concat(colors_1.grey[800]),
    },
    '& p': {
        fontSize: '2rem',
        fontWeight: 700,
        margin: 0,
    },
}); });
exports.SummaryIconWrapper = (0, system_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        height: 42,
        width: 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        borderRadius: '8px',
    });
});
