"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var localeEs = {
    column: {
        gender: 'Género',
        status: 'Estado',
    },
    status: {
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        CANCELED: 'Cancelado',
        SUSPENDED: 'Suspendido',
        PENDING: 'Pendiente',
        BLOCKED: 'Bloqueado',
        IN_PROGRESS_ASSIGN_CARD: 'En Proceso a Asignar Tarjetas',
        SENT_EMAIL_ACTIVATION: 'Correo Enviado para su Activación',
        PENDING_UPLOAD_FILE_OF_CLIENTS: 'Pendiente a Subida de Clientes',
        IN_PROGRESS_UPLOAD_FILE_OF_CLIENTS: 'En proceso de Subida de Clientes',
        CLIENT_ASSIGNED: 'Clientes Asignados',
        ERROR_CLIENT_ACTIVE: 'Error al Activar el Cliente',
        FAIL_PROCESSING_CLIENTS: 'Error al Procesar los Clientes',
        PENDING_ASSIGN_CARDS: 'Pendiente a Asignar Tarjetas',
        PENDING_ASSIGN_CARD: 'Pendiente a Asignar Tarjeta',
        ERROR_ASSIGNED_CARD: 'Error al Asignar Tarjeta',
        CARD_ASSIGNED: 'Tarjeta Asignada',
        FAIL_ASSIGNED_CARDS: 'Error al Asignar las Tarjetas',
        SUCCESS_ASSIGNED_CARDS: 'Tarjetas Asignadas con Éxito',
        PENDING_RECHARGE_BALANCES: 'Pendiente a Realizar las Recargas',
        PENDING_RECHARGE_BALANCE: 'Pendiente a Realizar la Recarga',
        ERROR_RECHARGED: 'Error en la Recarga',
        SUCCESS_RECHARGED: 'Recarga con Éxito',
        FAIL_RECHARGE_BALANCES: 'Error al Realizar las Recargas',
        PENDING_SEND_EMAIL: 'Pendiente a Enviar el Correo',
        FINISHED: 'Finalizado',
        PROCESS_SEND_EMAIL: 'En proceso a Enviar Correo',
        FAIL_PROCESS_SEND_EMAIL: 'Error en el Proceso de Enviar Correo',
        ERROR: 'Error',
        SUCCESS: 'Éxito',
        REPLACED: 'Reemplazado',
        IN_PROCESS: 'En Proceso',
    },
    merchant_transaction_type: {
        DEBIT: 'Débito',
        CREDIT: 'Crédito',
    },
    merchant_transaction_filter: {
        TYPE: 'Tipo de transacción',
        AMOUNT: 'Monto de transacción',
        DATE: 'Fecha de transacción',
        CONFIRM: 'Código de transacción',
    },
    gender: {
        F: 'Femenino',
        M: 'Masculino',
    },
    color: {
        ACTIVE: 'success',
        INACTIVE: 'default',
        CANCELED: 'error',
        SUSPENDED: 'warning',
        PENDING: 'default',
        BLOCKED: 'error',
        IN_PROGRESS_ASSIGN_CARD: 'info',
        SENT_EMAIL_ACTIVATION: 'success',
        PENDING_UPLOAD_FILE_OF_CLIENTS: 'success',
        IN_PROGRESS_UPLOAD_FILE_OF_CLIENTS: 'info',
        CLIENT_ASSIGNED: 'success',
        ERROR_CLIENT_ACTIVE: 'error',
        FAIL_PROCESSING_CLIENTS: 'error',
        PENDING_ASSIGN_CARDS: 'success',
        PENDING_ASSIGN_CARD: 'success',
        ERROR_ASSIGNED_CARD: 'error',
        CARD_ASSIGNED: 'success',
        FAIL_ASSIGNED_CARDS: 'error',
        SUCCESS_ASSIGNED_CARDS: 'success',
        PENDING_RECHARGE_BALANCES: 'success',
        PENDING_RECHARGE_BALANCE: 'success',
        ERROR_RECHARGED: 'error',
        SUCCESS_RECHARGED: 'success',
        FAIL_RECHARGE_BALANCES: 'error',
        PENDING_SEND_EMAIL: 'success',
        FINISHED: 'success',
        SUSPEND: 'warning',
        ACTIVATE: 'success',
        PROCESS_SEND_EMAIL: 'info',
        FAIL_PROCESS_SEND_EMAIL: 'error',
        RE_ASSIGN: 'success',
        REPLACE: 'success',
        BLOCK: 'error',
        ERROR: 'error',
        SUCCESS: 'success',
        REPLACED: 'success',
        RECHARGE: 'success',
        ASSIGN: 'success',
        DEBIT: 'error',
        CREDIT: 'success',
        IN_PROCESS: 'warning',
    },
    actionClientCard: {
        REPOSITION: 'reposición',
        SUSPENTION: 'suspensión',
        BLOCK: 'bloquear',
        ACTIVATE: 'activar',
        RE_ASSIGN: 'reposición',
        REPLACE: 'reposición',
    },
    clientCardReason: {
        ACTIVE: 'activar',
        INACTIVE: 'inactivar',
        CANCELED: 'cancelar',
        REPOSITION: 'reponer',
        SUSPENDED: 'suspender',
        BLOCKED: 'bloquear',
        PENDING: 'pendiente',
        SUSPEND: 'suspender',
        ACTIVATE: 'activar',
        RE_ASSIGN: 'reponer',
        REPLACE: 'reponer',
        BLOCK: 'bloquear',
        RECHARGE: 'recargar',
        ASSIGN: 'asignar',
    },
    report_type: {
        CARDS: 'Tarjetas',
    },
};
exports.default = localeEs;
