"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var merchantListData = [
    {
        "id": "6721523b-5b4f-70d4-a19c-de83c2433193",
        "status": "ACTIVE",
        "created_at": "2022-11-14T18:01:39.55693+00:00",
        "updated_at": "2022-11-14T18:01:39.55693+00:00",
        "deleted_at": null,
        "is_deleted": false,
        "is_deleted_by": null,
        "code_client": "1002",
        "core_merchant_id": "3f96d2f5-685b-c08f-f6a3-5223da7bbe1c",
        "company_logo": "banexcoin-logo.png",
        "company_name": "BANEXCOIN S.A.",
        merchant_wallet: {
            id: '516aca32-c10c-4a80-9eac-f0aae11021f2',
            merchant_id: "6721523b-5b4f-70d4-a19c-de83c2433193",
            currency_code: 'PEN',
            balance: '190.00',
            status: 'ACTIVE',
            created_at: "2023-03-14T18:01:39.55693+00:00",
        }
    },
    {
        "id": "8bc6b75f-6174-cb04-020a-b7364177b8bb",
        "status": "ACTIVE",
        "created_at": "2022-11-14T18:07:23.273973+00:00",
        "updated_at": "2022-11-14T18:07:23.273973+00:00",
        "deleted_at": null,
        "is_deleted": false,
        "is_deleted_by": null,
        "code_client": "1003",
        "core_merchant_id": "1f35b030-56b7-fe6c-8496-f2b60c1f0b01",
        "company_logo": "electron-logo.png",
        "company_name": "ELECTRON MOTORS S.A.",
        merchant_wallet: {
            id: 'ba04461f-f1bd-49a2-8fab-ed807045c693',
            merchant_id: "8bc6b75f-6174-cb04-020a-b7364177b8bb",
            currency_code: 'PEN',
            balance: '0.00',
            status: 'ACTIVE',
            created_at: "2023-03-14T18:01:39.55693+00:00",
        }
    },
    {
        "id": "788097d9-43a4-49c0-298e-13c2dccbab94",
        "status": "ACTIVE",
        "created_at": "2022-11-21T21:33:08.548481+00:00",
        "updated_at": "2022-11-21T21:33:08.548481+00:00",
        "deleted_at": null,
        "is_deleted": false,
        "is_deleted_by": null,
        "code_client": "1005",
        "core_merchant_id": "0f966a54-ed72-bdb0-731b-599bb52d5025",
        "company_logo": "gbc-store-logo.png",
        "company_name": "GBC STORE SAC",
        merchant_wallet: {
            id: '1e1fd6df-9104-46e5-bd3b-15cb9822e9d6',
            merchant_id: "788097d9-43a4-49c0-298e-13c2dccbab94",
            currency_code: 'PEN',
            balance: '150.00',
            status: 'ACTIVE',
            created_at: "2023-03-14T18:01:39.55693+00:00",
        }
    },
];
exports.default = merchantListData;
