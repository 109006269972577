"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInactivityLogout = exports.InactivityLogoutProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var hooks_1 = require("../hooks");
var Auth_1 = require("./Auth");
var date_fns_1 = require("date-fns");
var InactivityLogoutContext = (0, react_1.createContext)({});
var InactivityLogoutProvider = function (_a) {
    var children = _a.children;
    var _b = (0, Auth_1.useAuth)(), tokens = _b.tokens, logout = _b.logout;
    var isPageVisible = (0, hooks_1.usePageVisibility)();
    var isPageActive = (0, hooks_1.usePageActivity)();
    var _c = (0, react_1.useState)(null), dateTimeLogout = _c[0], setDateTimeLogout = _c[1];
    var minutesLogout = 15;
    (0, react_1.useEffect)(function () {
        if (Object.keys(tokens).length > 0 && (!isPageVisible || !isPageActive)) {
            setDateTimeLogout((0, date_fns_1.addMinutes)(new Date(), minutesLogout));
        }
        else {
            resetInactivity();
        }
    }, [isPageVisible, isPageActive]);
    (0, react_1.useEffect)(function () {
        if (dateTimeLogout) {
            setTimeout(function () {
                if (new Date() >= dateTimeLogout) {
                    logout();
                }
            }, 1000 * 60 * minutesLogout);
        }
    }, [dateTimeLogout]);
    var resetInactivity = function () {
        setDateTimeLogout(null);
    };
    return ((0, jsx_runtime_1.jsx)(InactivityLogoutContext.Provider, __assign({ value: {} }, { children: children })));
};
exports.InactivityLogoutProvider = InactivityLogoutProvider;
var useInactivityLogout = function () {
    var context = (0, react_1.useContext)(InactivityLogoutContext);
    if (!context) {
        throw new Error('InactivityLogoutContext must be used within a InactivityLogoutProvider ');
    }
    return context;
};
exports.useInactivityLogout = useInactivityLogout;
