"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleError = void 0;
var react_toastify_1 = require("react-toastify");
var get_validation_error_1 = require("./get-validation-error");
var handleError = function (error) {
    react_toastify_1.toast.error((0, get_validation_error_1.getValidationError)(error.code));
    return;
};
exports.handleError = handleError;
