"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var components_1 = require("../../../../components");
var translations_1 = require("../../../../translations");
var utilities_1 = require("../../../../utilities");
var TransactionDetail_styled_1 = require("./TransactionDetail.styled");
var TransactionDetail = function (_a) {
    var transaction = _a.transaction;
    var color = transaction.type.trim().toLowerCase().includes('recarga')
        ? '#61cf59'
        : '#c2544a';
    var signAmount = transaction.type.trim().toLowerCase().includes('recarga')
        ? '+'
        : '-';
    var textAmount = (0, utilities_1.amountFormatByCurrencyCode)({
        amount: Number(transaction.amount),
        currency_code: transaction.currency,
    });
    return ((0, jsx_runtime_1.jsxs)(TransactionDetail_styled_1.TransactionCard, { children: [(0, jsx_runtime_1.jsx)(TransactionDetail_styled_1.ContentLeft, __assign({ sx: { display: { xs: 'none', sm: 'block' } } }, { children: (0, jsx_runtime_1.jsxs)("p", { children: ["#", transaction.sequence] }) })), (0, jsx_runtime_1.jsxs)(TransactionDetail_styled_1.ContentLeft, { children: [(0, jsx_runtime_1.jsx)("h5", { children: transaction.commerce }), (0, jsx_runtime_1.jsxs)("p", { children: [transaction.date, " - ", transaction.hour] })] }), (0, jsx_runtime_1.jsx)(TransactionDetail_styled_1.ContentLeft, __assign({ sx: { display: { xs: 'none', sm: 'block' } } }, { children: (0, jsx_runtime_1.jsx)(components_1.ChipComponent, { variant: "outlined", color: transaction.transaction_type in translations_1.localeEs['color']
                        ? translations_1.localeEs['color'][transaction.transaction_type]
                        : 'default', label: transaction.transaction_type in
                        translations_1.localeEs['merchant_transaction_type']
                        ? translations_1.localeEs['merchant_transaction_type'][transaction.transaction_type]
                        : transaction.transaction_type }) })), (0, jsx_runtime_1.jsx)(TransactionDetail_styled_1.ContentRight, { children: (0, jsx_runtime_1.jsxs)("p", __assign({ style: { color: color } }, { children: [signAmount, " ", textAmount] })) })] }));
};
exports.default = TransactionDetail;
