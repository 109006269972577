"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageError = exports.Wrapper = void 0;
var system_1 = require("@mui/system");
exports.Wrapper = (0, system_1.styled)('div')(function () { return ({
    height: '100svh',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    padding: '20px',
    textAlign: 'center'
}); });
exports.ImageError = (0, system_1.styled)('img')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            width: '80%'
        },
        _b[theme.breakpoints.up('sm')] = {
            width: '60%'
        },
        _b);
});
