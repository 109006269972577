"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormActions = exports.RecoveryPasswordLink = exports.RecoveryWrapper = exports.FormGroup = exports.FormAuth = exports.WelcomeMessage = exports.WelcomeWrapper = exports.ImgLogo = exports.LoginHeaderForm = exports.CardForm = exports.PageWrapper = void 0;
var colors_1 = require("@mui/material/colors");
var system_1 = require("@mui/system");
var react_router_dom_1 = require("react-router-dom");
exports.PageWrapper = (0, system_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100vh',
        width: '100%',
        backgroundColor: colors_1.grey[100],
    });
});
exports.CardForm = (0, system_1.styled)('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px 34px;\n  border-radius: 8px;\n  background-color: white;\n  display: grid;\n  gap: 20px;\n  width: 100%;\n  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);\n  @media (min-width: 768px) {\n    width: 500px;\n  }\n"], ["\n  padding: 40px 34px;\n  border-radius: 8px;\n  background-color: white;\n  display: grid;\n  gap: 20px;\n  width: 100%;\n  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);\n  @media (min-width: 768px) {\n    width: 500px;\n  }\n"])));
exports.LoginHeaderForm = (0, system_1.styled)('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  place-items: center;\n  text-align: center;\n  gap: 30px;\n  margin-top: 30px;\n"], ["\n  display: grid;\n  place-items: center;\n  text-align: center;\n  gap: 30px;\n  margin-top: 30px;\n"])));
exports.ImgLogo = (0, system_1.styled)('img')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 60%;\n  height: 60px;\n  aspect-ratio: 3/2;\n  object-fit: contain;\n"], ["\n  width: 60%;\n  height: 60px;\n  aspect-ratio: 3/2;\n  object-fit: contain;\n"])));
exports.WelcomeWrapper = (0, system_1.styled)('div')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 10px;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 10px;\n"])));
exports.WelcomeMessage = (0, system_1.styled)('p')(function () { return ({
    margin: 0,
    color: "".concat(colors_1.grey[600]),
}); });
exports.FormAuth = (0, system_1.styled)('form')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  gap: 30px;\n  margin: 30px 0;\n"], ["\n  display: grid;\n  gap: 30px;\n  margin: 30px 0;\n"])));
exports.FormGroup = (0, system_1.styled)('div')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 15px;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 15px;\n"])));
exports.RecoveryWrapper = (0, system_1.styled)('div')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
exports.RecoveryPasswordLink = (0, system_1.styled)(react_router_dom_1.Link)(function (_a) {
    var theme = _a.theme;
    return ({
        fontWeight: 500,
        textDecoration: 'none',
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    });
});
exports.FormActions = (0, system_1.styled)('div')(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
