"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentText = exports.SubTitle = exports.ContentCard = exports.DetailContent = exports.CampaignDescription = exports.IconWrapper = exports.HeadContent = exports.ContentWrapper = exports.HeaderContent = void 0;
var colors_1 = require("@mui/material/colors");
var styles_1 = require("@mui/material/styles");
exports.HeaderContent = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '20px',
            width: '100%'
        },
        _b[theme.breakpoints.up('sm')] = {
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        _b);
});
exports.ContentWrapper = (0, styles_1.styled)('div')(function () { return ({
    marginTop: '20px',
}); });
exports.HeadContent = (0, styles_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '10px',
}); });
exports.IconWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '46px',
        height: '46px',
        borderRadius: '5px',
        display: 'flex',
        placeItems: 'center',
        padding: '10px',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
    });
});
exports.CampaignDescription = (0, styles_1.styled)('h3')(function () { return ({
    fontSize: '18px',
    fontWeight: '500',
}); });
exports.DetailContent = (0, styles_1.styled)('div')(function () { return ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '10px',
}); });
exports.ContentCard = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        padding: '15px',
        borderRadius: '8px',
        border: "1px solid #E6E6E6",
    });
});
exports.SubTitle = (0, styles_1.styled)('h6')(function () { return ({
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
}); });
exports.ContentText = (0, styles_1.styled)('p')(function () { return ({
    fontSize: 16,
    fontWeight: 400,
    color: "".concat(colors_1.grey[600]),
    margin: 0,
}); });
