"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TRANSACTIONS_BY_WALLET_ID = exports.GET_WALLETS_BY_MERCHANT_ID = exports.GET_DETAIL_MERCHANT = exports.GET_REPORT_ALL_HISTORY = exports.GET_REPORT_ALL_CLIENT_CARD = exports.UPDATE_CAMPAIGN = exports.DELETE_CAMPAIGN = exports.CREATE_CAMPAIGN = exports.GET_ALL_CAMPAIGNS_BY_MERCHANT_ID = exports.GET_ALL_CAMPAIGNS_FOR_ADMIN = exports.GET_ALL_MERCHANTS = exports.GET_CARD_EVENT_LOGS = exports.BLOCK_CARD_ACTION = exports.SUSPEND_CARD_ACTION = exports.ACTIVATE_CARD_ACTION = exports.REPLACEMENT_CARD_ACTION = exports.GET_TRANSACTIONS_BY_CLIENT_CARD_ID = exports.GET_DETAIL_CLIENT = exports.GET_CLIENTS = exports.GET_DETAIL_CLIENT_CARD = exports.GET_CLIENT_CARDS = exports.GET_DETAIL_CAMPAIGN = exports.GET_CAMPAIGNS = exports.GET_SUMMARY_COUNT = void 0;
exports.GET_SUMMARY_COUNT = 'GET_SUMMARY_COUNT';
exports.GET_CAMPAIGNS = 'GET_CAMPAIGNS';
exports.GET_DETAIL_CAMPAIGN = 'GET_DETAIL_CAMPAIGN';
exports.GET_CLIENT_CARDS = 'GET_CLIENT_CARDS';
exports.GET_DETAIL_CLIENT_CARD = 'GET_DETAIL_CLIENT_CARD';
exports.GET_CLIENTS = 'GET_CLIENTS';
exports.GET_DETAIL_CLIENT = 'GET_DETAIL_CLIENT';
exports.GET_TRANSACTIONS_BY_CLIENT_CARD_ID = 'GET_TRANSACTIONS_BY_CLIENT_CARD_ID';
exports.REPLACEMENT_CARD_ACTION = 'REPLACEMENT_CARD_ACTION';
exports.ACTIVATE_CARD_ACTION = 'ACTIVATE_CARD_ACTION';
exports.SUSPEND_CARD_ACTION = 'SUSPEND_CARD_ACTION';
exports.BLOCK_CARD_ACTION = 'BLOCK_CARD_ACTION';
exports.GET_CARD_EVENT_LOGS = 'GET_CARD_EVENT_LOGS';
exports.GET_ALL_MERCHANTS = 'GET_ALL_MERCHANTS';
exports.GET_ALL_CAMPAIGNS_FOR_ADMIN = 'GET_ALL_CAMPAIGNS_FOR_ADMIN';
exports.GET_ALL_CAMPAIGNS_BY_MERCHANT_ID = 'GET_ALL_CAMPAIGNS_BY_MERCHANT_ID';
exports.CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
exports.DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
exports.UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
exports.GET_REPORT_ALL_CLIENT_CARD = 'GET_REPORT_ALL_CLIENT_CARD';
exports.GET_REPORT_ALL_HISTORY = 'GET_REPORT_ALL_HISTORY';
exports.GET_DETAIL_MERCHANT = 'GET_DETAIL_MERCHANT';
exports.GET_WALLETS_BY_MERCHANT_ID = 'GET_WALLETS_BY_MERCHANT_ID';
exports.GET_TRANSACTIONS_BY_WALLET_ID = 'GET_TRANSACTIONS_BY_WALLET_ID';
