"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    gbc: {
        darkBlue: '#1C3D53',
        blue: '#20419A',
        violet: '#656DFD',
        skyBlue: '#00BDF2',
    },
    text: {
        secondary: '#7E7E7E',
    },
};
