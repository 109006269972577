"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelperText = void 0;
var colors_1 = require("@mui/material/colors");
var system_1 = require("@mui/system");
exports.HelperText = (0, system_1.styled)('p')(function () { return ({
    margin: 0,
    fontSize: 14,
    color: "".concat(colors_1.grey.A700),
}); });
