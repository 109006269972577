"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useDisclosure() {
    var refReferenceItem = (0, react_1.useRef)(null);
    var _a = (0, react_1.useState)(false), isActive = _a[0], setIsActive = _a[1];
    var toggle = (0, react_1.useCallback)(function () {
        setIsActive(function (state) { return !state; });
    }, []);
    var onClose = (0, react_1.useCallback)(function () {
        setIsActive(false);
    }, []);
    var onOpen = (0, react_1.useCallback)(function () {
        setIsActive(true);
    }, []);
    (0, react_1.useEffect)(function () {
        var checkIfClickedOutside = function (e) {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isActive &&
                refReferenceItem.current &&
                !refReferenceItem.current.contains(e.target)) {
                setIsActive(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return function () {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isActive]);
    return {
        isOpen: isActive,
        toggle: toggle,
        onOpen: onOpen,
        onClose: onClose,
        refReferenceItem: refReferenceItem,
    };
}
exports.default = useDisclosure;
