"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumClientCardStatus = void 0;
var EnumClientCardStatus;
(function (EnumClientCardStatus) {
    EnumClientCardStatus["ACTIVE"] = "ACTIVE";
    EnumClientCardStatus["INACTIVE"] = "INACTIVE";
    EnumClientCardStatus["CANCELED"] = "CANCELED";
    EnumClientCardStatus["SUSPENDED"] = "SUSPENDED";
    EnumClientCardStatus["PENDING"] = "PENDING";
    EnumClientCardStatus["BLOCKED"] = "BLOCKED";
})(EnumClientCardStatus = exports.EnumClientCardStatus || (exports.EnumClientCardStatus = {}));
