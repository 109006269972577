"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var CustomTableRow = function (_a) {
    var onClick = _a.onClick, _b = _a.isRowSelected, isRowSelected = _b === void 0 ? false : _b, item = _a.item, columns = _a.columns, rest = __rest(_a, ["onClick", "isRowSelected", "item", "columns"]);
    function renderTableCell(header, isFirst, isLast) {
        if (isFirst === void 0) { isFirst = false; }
        if (isLast === void 0) { isLast = false; }
        switch (header.type) {
            case 'text':
                return ((0, jsx_runtime_1.jsx)(material_1.TableCell, __assign({ align: header.align }, { children: header.valueGetter
                        ? header.valueGetter({ row: item })
                        : item[header.field] })));
            case 'component':
                return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: header.valueGetter
                        ? header.valueGetter({ row: item })
                        : item[header.field] }));
        }
    }
    return ((0, jsx_runtime_1.jsx)(material_1.TableRow, __assign({ hover: true, onClick: onClick ? function () { return onClick(item); } : function () { } }, rest, { children: columns === null || columns === void 0 ? void 0 : columns.filter(function (c) { return Boolean(c); }).map(function (header, i) {
            return (0, react_1.cloneElement)(renderTableCell(header, i === 0, i + 1 === (columns === null || columns === void 0 ? void 0 : columns.filter(function (c) { return Boolean(c); }).length)), {
                key: i,
            });
        }) })));
};
exports.default = CustomTableRow;
