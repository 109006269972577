"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateBeforeCurrentDate = exports.nowDateFormat = exports.isValidDate = exports.formatDate = void 0;
var date_fns_1 = require("date-fns");
var formatDate = function (_a) {
    var date = _a.date, _b = _a.formatTypeDate, formatTypeDate = _b === void 0 ? 'dd-MM-yyyy HH:mm:ss' : _b;
    if (typeof date === 'string' &&
        date.match(/^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i))
        return (0, date_fns_1.format)((0, date_fns_1.parseJSON)(date), formatTypeDate);
    if (typeof date === 'number' || date instanceof Date)
        return (0, date_fns_1.format)(date, formatTypeDate);
    return 'Formato no válido';
};
exports.formatDate = formatDate;
var isValidDate = function (date) {
    return (0, date_fns_1.isValid)((0, date_fns_1.parseJSON)(String(date)));
};
exports.isValidDate = isValidDate;
var nowDateFormat = function (_a) {
    var _b = _a.formatTypeDate, formatTypeDate = _b === void 0 ? 'dd-MM-yyyy HH:mm:ss' : _b;
    return (0, date_fns_1.format)(new Date(), formatTypeDate);
};
exports.nowDateFormat = nowDateFormat;
var getDateBeforeCurrentDate = function (_a) {
    var _b = _a.formatTypeDate, formatTypeDate = _b === void 0 ? 'dd-MM-yyyy HH:mm:ss' : _b, _c = _a.days, days = _c === void 0 ? 1 : _c;
    return (0, date_fns_1.format)((0, date_fns_1.subDays)(new Date(), days), formatTypeDate);
};
exports.getDateBeforeCurrentDate = getDateBeforeCurrentDate;
