"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wrapper = void 0;
var system_1 = require("@mui/system");
exports.Wrapper = (0, system_1.styled)('div')(function () { return ({
    height: '100svh',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
}); });
