"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumActionDetailClientCard = void 0;
var EnumActionDetailClientCard;
(function (EnumActionDetailClientCard) {
    EnumActionDetailClientCard["REPOSITION"] = "REPOSITION";
    EnumActionDetailClientCard["SUSPENTION"] = "SUSPENTION";
    EnumActionDetailClientCard["BLOCK"] = "BLOCK";
    EnumActionDetailClientCard["ACTIVATE"] = "ACTIVATE";
})(EnumActionDetailClientCard = exports.EnumActionDetailClientCard || (exports.EnumActionDetailClientCard = {}));
