"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.amountFormatByCurrencyCode = void 0;
var amountFormatByCurrencyCode = function (_a) {
    var _b = _a.amount, amount = _b === void 0 ? 0 : _b, _c = _a.currency_code, currency_code = _c === void 0 ? 'PEN' : _c;
    return Number(amount).toLocaleString('es-PE', {
        style: 'currency',
        currency: currency_code,
        minimumFractionDigits: 2,
    });
};
exports.amountFormatByCurrencyCode = amountFormatByCurrencyCode;
