"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventLogColumn = exports.EventLogHead = exports.TransactionColumn = exports.TransactionHead = exports.ContentCardWrapper = exports.ContentClientCard = exports.ContentClientWrapper = exports.ClientCardNumber = exports.LogoMastercard = exports.ClientCardContentWrapper = exports.ClientCardHeader = exports.ClientCardWrapper = exports.SectionTitle = exports.ContentText = exports.SubTitle = exports.ContentCard = exports.DetailContent = exports.FullNameText = exports.HeadContent = exports.ContentWrapper = exports.HeaderContent = void 0;
var styles_1 = require("@mui/material/styles");
var colors_1 = require("@mui/material/colors");
var material_1 = require("@mui/material");
exports.HeaderContent = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '20px',
            width: '100%'
        },
        _b[theme.breakpoints.up('sm')] = {
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        _b);
});
exports.ContentWrapper = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            marginBlock: '20px',
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: '1fr'
        },
        _b[theme.breakpoints.up('sm')] = {
            gap: '20px',
            gridTemplateColumns: '500px 1fr',
        },
        _b);
});
exports.HeadContent = (0, styles_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
}); });
exports.FullNameText = (0, styles_1.styled)('h3')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            fontSize: 16,
            fontWeight: '500',
            wordSpacing: '2px',
            textTransform: 'uppercase',
            margin: 0
        },
        _b[theme.breakpoints.up('sm')] = {
            fontSize: 16,
        },
        _b);
});
exports.DetailContent = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            marginTop: '20px',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '10px'
        },
        _b[theme.breakpoints.up('sm')] = {
            marginTop: 0,
        },
        _b);
});
exports.ContentCard = (0, styles_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        padding: '15px',
        borderRadius: '8px',
        border: "1px solid ".concat(colors_1.grey[300]),
    });
});
exports.SubTitle = (0, styles_1.styled)('h6')(function () { return ({
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
}); });
exports.ContentText = (0, styles_1.styled)('p')(function () { return ({
    fontSize: 16,
    fontWeight: 400,
    color: "".concat(colors_1.grey[600]),
    margin: 0,
}); });
exports.SectionTitle = (0, styles_1.styled)('h5')(function () { return ({
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '15px',
    color: "".concat(colors_1.grey[600]),
    margin: 0,
    marginTop: '10px',
}); });
exports.ClientCardWrapper = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            overflow: 'hidden',
            padding: '20px',
            borderRadius: 16,
            position: 'relative',
            background: 'white',
            border: '1px solid gray',
            width: '100%',
            margin: 0,
            marginBottom: 10
        },
        _b[theme.breakpoints.up('sm')] = {
            margin: '0 20px',
            width: '350px',
        },
        _b);
});
exports.ClientCardHeader = (0, styles_1.styled)('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}); });
exports.ClientCardContentWrapper = (0, styles_1.styled)('div')(function () { return ({
    marginTop: 50,
}); });
exports.LogoMastercard = (0, styles_1.styled)('img')(function () { return ({
    aspectRatio: 3 / 4,
    width: 30,
    objectFit: 'contain',
}); });
exports.ClientCardNumber = (0, styles_1.styled)('p')(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: 18,
        fontWeight: 400,
        color: "".concat(colors_1.grey[600]),
        letterSpacing: '5px',
        marginTop: '10px',
        marginBottom: '0',
    });
});
exports.ContentClientWrapper = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '20px',
            padding: '15px',
            borderRadius: '8px',
            border: "1px solid ".concat(colors_1.grey[300])
        },
        _b[theme.breakpoints.up('sm')] = {
            gap: '30px',
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        _b);
});
exports.ContentClientCard = (0, styles_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
    });
});
exports.ContentCardWrapper = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '20px',
            padding: '15px',
            borderRadius: '8px',
            border: "1px solid ".concat(colors_1.grey[300])
        },
        _b[theme.breakpoints.up('sm')] = {
            gridTemplateColumns: '1fr 0.53fr',
        },
        _b);
});
exports.TransactionHead = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: '10px 15px',
            borderRadius: 16,
            border: "1px solid ".concat(colors_1.grey[300]),
            display: 'grid',
            gridTemplateColumns: '1fr 6rem',
            gap: 20,
            alignItems: 'center',
            marginBottom: '5px'
        },
        _b[theme.breakpoints.up('sm')] = {
            gridTemplateColumns: '4rem 1fr 1fr 6rem',
            gap: 20,
        },
        _b);
});
exports.TransactionColumn = (0, styles_1.styled)(material_1.Box)(function () { return ({
    '& h5': {
        margin: 0,
    },
}); });
exports.EventLogHead = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: '10px 15px',
            borderRadius: 16,
            border: "1px solid ".concat(colors_1.grey[300]),
            display: 'none',
            marginBottom: '5px'
        },
        _b[theme.breakpoints.up('sm')] = {
            display: 'grid',
            gridTemplateColumns: '6rem 12rem 1fr 8rem 8rem 9rem',
            gap: 20,
        },
        _b);
});
exports.EventLogColumn = (0, styles_1.styled)(material_1.Box)(function () { return ({
    '& h5': {
        margin: 0,
    },
}); });
