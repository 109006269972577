"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var usePageActivity = function () {
    var _a = (0, react_1.useState)(true), isActiveElement = _a[0], setIsActiveElement = _a[1];
    var handleActiveElement = function () {
        setIsActiveElement(true);
    };
    var handleInactiveElement = function () {
        setIsActiveElement(false);
    };
    var handleActiveToggleElement = function () {
        var timer;
        clearTimeout(timer);
        setIsActiveElement(true);
        timer = setTimeout(function () {
            setIsActiveElement(false);
        }, 3000);
    };
    (0, react_1.useEffect)(function () {
        document.addEventListener('mouseenter', handleActiveElement);
        document.addEventListener('mouseleave', handleInactiveElement);
        document.addEventListener('touchstart', handleActiveElement);
        document.addEventListener('touchend', handleInactiveElement);
        document.addEventListener('mousemove', handleActiveToggleElement);
        return function () {
            document.removeEventListener('mouseenter', handleActiveElement);
            document.removeEventListener('mouseleave', handleInactiveElement);
            document.removeEventListener('touchstart', handleActiveElement);
            document.removeEventListener('touchend', handleInactiveElement);
            document.removeEventListener('mousemove', handleActiveToggleElement);
        };
    }, []);
    return isActiveElement;
};
exports.default = usePageActivity;
