"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardUtil = void 0;
var DashboardUtil = /** @class */ (function () {
    function DashboardUtil() {
    }
    DashboardUtil.getCountSummaryList = function (summary) {
        return Object.keys(summary).map(function (key) {
            if (key === 'clients') {
                return {
                    label: 'Clientes',
                    count: summary[key].total,
                    icon: 'groups',
                    href: '/clients',
                };
            }
            if (key === 'campaigns') {
                return {
                    label: 'Campañas',
                    count: summary[key].total,
                    icon: 'redeem',
                    href: '/campaigns',
                };
            }
            if (key === 'cards') {
                return {
                    label: 'Tarjetas',
                    count: summary[key].total,
                    icon: 'credit_card',
                    href: '/client-cards',
                };
            }
        });
    };
    return DashboardUtil;
}());
exports.DashboardUtil = DashboardUtil;
