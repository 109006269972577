"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var window_size_1 = require("@react-hook/window-size");
function WrapperDraw(_a) {
    var children = _a.children, _b = _a.width, width = _b === void 0 ? 500 : _b;
    var widthSize = (0, window_size_1.useWindowWidth)();
    return ((0, jsx_runtime_1.jsx)(material_1.Container, __assign({ maxWidth: "xl", sx: { width: widthSize >= width ? width : widthSize, pt: 4 } }, { children: children })));
}
function DrawerComponentNormal(_a) {
    var children = _a.children, onClose = _a.onClose, open = _a.open, _b = _a.anchor, anchor = _b === void 0 ? 'right' : _b, width = _a.width;
    return ((0, jsx_runtime_1.jsx)(material_1.Drawer, __assign({ onClose: onClose, open: open, anchor: anchor }, { children: (0, jsx_runtime_1.jsx)(WrapperDraw, __assign({ width: width }, { children: children })) })));
}
function DrawerComponent(_a) {
    var children = _a.children, onClose = _a.onClose, onOpen = _a.onOpen, open = _a.open, _b = _a.anchor, anchor = _b === void 0 ? 'right' : _b, width = _a.width;
    var iOS = typeof navigator !== 'undefined' &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);
    return ((0, jsx_runtime_1.jsx)(material_1.SwipeableDrawer, __assign({ onOpen: onOpen, onClose: onClose, open: open, disableBackdropTransition: !iOS, disableDiscovery: iOS, anchor: anchor }, { children: (0, jsx_runtime_1.jsx)(WrapperDraw, __assign({ width: width }, { children: children })) })));
}
DrawerComponent.DrawerComponent = DrawerComponent;
DrawerComponent.DrawerComponentNormal = DrawerComponentNormal;
exports.default = DrawerComponent;
