"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_error_boundary_1 = require("react-error-boundary");
var react_query_1 = require("react-query");
var constants_1 = require("../../../constants");
var services_1 = require("../../../services");
var utilities_1 = require("../../../utilities");
var useFetchAllMerchants = function (props) {
    var showBoundary = (0, react_error_boundary_1.useErrorBoundary)().showBoundary;
    return (0, react_query_1.useQuery)([constants_1.GET_ALL_MERCHANTS], function () { return services_1.MerchantService.getAllMerchants(); }, {
        onSuccess: props === null || props === void 0 ? void 0 : props.onSuccess,
        onError: function (error) {
            (0, utilities_1.handleError)(error);
            showBoundary(error);
        },
    });
};
exports.default = useFetchAllMerchants;
