"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryParamsUtils = void 0;
var QueryParamsUtils = /** @class */ (function () {
    function QueryParamsUtils() {
    }
    QueryParamsUtils.getQueryParamsInitialValues = function (initialParamValues, queryParams) {
        return Object.keys(initialParamValues)
            .map(function (key) {
            var _a, _b;
            return queryParams.get(key)
                ? (_a = {},
                    _a[key] = queryParams.get(key),
                    _a) : (_b = {},
                _b[key] = initialParamValues[key],
                _b);
        })
            .reduce(function (result, item) {
            var key = Object.keys(item)[0];
            if (key === 'page' && Number(item[key]) > 0)
                result[key] = Number(item[key]) - 1;
            else if (String(item[key]) !== '' &&
                !isNaN(Number(item[key])) &&
                typeof Number(item[key]) === 'number')
                result[key] = Number(item[key]);
            else
                result[key] = item[key];
            return result;
        }, {});
    };
    QueryParamsUtils.getQueryParamsMultipleInitialValues = function (_a) {
        var initialParamValues = _a.initialParamValues, queryParams = _a.queryParams;
        return Object.keys(initialParamValues)
            .map(function (key) {
            var _a, _b;
            if (queryParams.get(key))
                return _a = {},
                    _a[key] = queryParams.getAll(key),
                    _a;
            else
                return _b = {},
                    _b[key] = initialParamValues[key],
                    _b;
        })
            .reduce(function (result, item) {
            var key = Object.keys(item)[0];
            if (key === 'page' && Number(item[key]) > 0)
                result[key] = Number(item[key]) - 1;
            else if (String(item[key]) !== '' &&
                !isNaN(Number(item[key])) &&
                typeof Number(item[key]) === 'number')
                result[key] = Number(item[key]);
            else
                result[key] = item[key];
            return result;
        }, {});
    };
    return QueryParamsUtils;
}());
exports.QueryParamsUtils = QueryParamsUtils;
